<template>
  <div id="clinic-data">
    <div class="clinic-data">
      <div class="d-flex flex-row">
        <div class="image-container">
          <div class="image" @click="$refs.FileInput.click()">
            <img
              v-if="brandTemporaryUrl && image.cropedImage === ''"
              :src="brandTemporaryUrl"
              width="240"
            />
            <img
              v-if="image.cropedImage != ''"
              :src="image.cropedImage"
              width="240"
            />
            <span v-if="!brandTemporaryUrl" class="center">
              <span class="icon">
                <Camera />
              </span>
              Fazer Upload
            </span>
          </div>
          <input
            ref="FileInput"
            type="file"
            style="display: none"
            accept="image/jpeg, image/png, tiff"
            @change="onFileSelect"
          />
          <p class="help">
            Envie um arquivo nos formatos .jpg ou .png. Com tamanho máximo de {{maxImageSize}}kb.
          </p>
        </div>
        <div class="d-flex flex-column flex-fill">
          <validation-observer
            ref="clinicForm"
            class="w-100"
            v-slot="{ invalid }"
          >
            <p class="title">Dados da clínica</p>
            <b-row>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicName">Nome da clínica</label>
                  <validation-provider
                    mode="lazy"
                    name="nome da clínica"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicName"
                      type="text"
                      v-model="clinicData.name"
                      class="form-control"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicDocument">CNPJ</label>
                  <validation-provider
                    mode="lazy"
                    name="cnpj"
                    rules="required|cnpj"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicDocument"
                      type="text"
                      v-model="clinicData.cnpj"
                      class="form-control"
                      v-mask="'##.###.###/####-##'"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicTelephone">Telefone</label>
                  <validation-provider
                    mode="lazy"
                    name="telefone"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicTelephone"
                      type="text"
                      v-model="clinicData.telephone"
                      class="form-control"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>

            </b-row>
            <b-row>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicTelephone">Telefone 2</label>
                  <validation-provider
                    mode="lazy"
                    name="telefone_2"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="clinicTelephone_2"
                      type="text"
                      placeholder="(00) 00000-0000"
                      v-model="clinicData.telephone_2"
                      class="form-control"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicTelephone">Telefone 3</label>
                  <validation-provider
                    mode="lazy"
                    name="telefone_3"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="clinicTelephone_3"
                      type="text"
                      placeholder="(00) 00000-0000"
                      v-model="clinicData.telephone_3"
                      class="form-control"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicEmail">E-mail do responsável</label>
                  <validation-provider
                    mode="lazy"
                    name="e-mail"
                    rules="required|email"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicEmail"
                      type="text"
                      v-model="clinicData.email"
                      class="form-control"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
            </b-row>

            <hr />

            <p class="title">Endereço</p>
            <b-row>
              <b-col cols="2">
                <div class="form-group">
                  <label for="clinicZipcode">CEP</label>
                  <validation-provider
                    mode="lazy"
                    name="cep"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicZipcode"
                      type="text"
                      v-model="clinicData.address.zipCode"
                      class="form-control"
                      v-mask="'#####-###'"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicAddress">Logradouro</label>
                  <validation-provider
                    mode="lazy"
                    name="logradouro"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicAddress"
                      type="text"
                      v-model="clinicData.address.address"
                      class="form-control"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="2">
                <div class="form-group">
                  <label for="clinicNumber">Nº</label>
                  <validation-provider
                    mode="lazy"
                    name="número"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicNumber"
                      ref="addressNumber"
                      type="text"
                      v-model="clinicData.address.number"
                      class="form-control"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="form-group">
                  <label for="clinicComplement">Complemento</label>
                  <input
                    autocomplete="off"
                    id="clinicComplement"
                    type="text"
                    v-model="clinicData.address.complement"
                    class="form-control"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="5">
                <div class="form-group">
                  <label for="clinicNeighborhood">Bairro</label>
                  <validation-provider
                    mode="lazy"
                    name="bairro"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicNeighborhood"
                      type="text"
                      v-model="clinicData.address.neighborhood"
                      class="form-control"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="5">
                <div class="form-group">
                  <label for="clinicCity">Cidade</label>
                  <validation-provider
                    mode="lazy"
                    name="cidade"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <input
                      autocomplete="off"
                      id="clinicCity"
                      type="text"
                      v-model="clinicData.address.city"
                      class="form-control"
                      :class="{ error: touched && errors.length > 0 }"
                    />
                    <span
                      class="error-message"
                      v-if="touched && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </validation-provider>
                </div>
              </b-col>
              <b-col cols="2">
                <div class="form-group">
                  <label for="clinicState">Estado</label>
                  <validation-provider
                    mode="lazy"
                    name="estado"
                    rules="required"
                    v-slot="{ touched, errors }"
                  >
                    <multiselect
                      id="clinicState"
                      v-model="clinicData.address.state"
                      :options="statesOptions"
                      :showLabels="false"
                      :searchable="false"
                      :allowEmpty="false"
                      placeholder="UF"
                      class="with-border"
                      :class="{ error: touched && errors.length > 0 }"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>

                      <template slot="noOptions">
                        Nenhuma opção
                      </template>

                      <template slot="noResult">
                        Nenhum resultado
                      </template>
                    </multiselect>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>

            <b-row class="d-flex flex-row-reverse">
              <b-col class="text-right">
                <b-button
                  v-can="'CcGer2'"
                  variant="primary"
                  :disabled="invalid"
                  @click="updateClinic"
                  >Salvar</b-button
                >
              </b-col>
            </b-row>
          </validation-observer>
        </div>
      </div>

      <b-modal id="crop-modal" hide-header hide-footer @hidden="resetCrop">
        <VueCropper
          ref="cropper"
          v-show="image.selectedFile"
          :src="image.selectedFile"
          :outputSize="maxImageSize"
          :cropBoxResizable="true"
        ></VueCropper>

        <div class="mt-2 text-right">
          <b-button
            variant="primary"
            @click="cropImage"
          >
            Recortar imagem
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as cep from 'cep-promise'

import Camera from '@/assets/icons/camera.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import brazilStates from '@/utils/states'

export default {
  name: 'ClinicDataView',
  components: {
    Camera,
    ChevronDown
  },
  data() {
    return {
      clinicImageUrl: process.env.VUE_APP_API_URL + 'storage/images/clinics/',
      dataLoaded: false,
      brandTemporaryUrl: null,
      maxImageSize: 400,
      clinicData: {
        name: '',
        cnpj: '',
        telephone: '',
        telephone_2: '',
        telephone_3: '',
        email: '',
        address: {
          zipCode: '',
          address: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      },
      image: {
        selectedFile: '',
        cropedImage: '',
        cropedBlob: ''
      },
      statesOptions: brazilStates.map(state => state.initial)
    }
  },
  mounted() {
    const isLoading = this.$loading.show()
    const clinic = JSON.parse(localStorage.getItem('clinic'))

    this.api
      .showClinic(clinic.id)
      .then(async res => {
        const resData = res.data
        if (resData.brand) {
          this.brandTemporaryUrl = await this.getBrandTemporaryUrl(resData.brand);
        }

        this.clinicData = {
          id: resData.id,
          brand: resData.brand,
          name: resData.name,
          cnpj: resData.cnpj,
          telephone: resData.telephone,
          telephone_2: resData.telephone_2,
          telephone_3: resData.telephone_3,
          email: resData.email,
          address: {
            zipCode: resData.address !== null ? resData.address.zipCode : '',
            address: resData.address !== null ? resData.address.address : '',
            number: resData.address !== null ? resData.address.number : '',
            complement:
              resData.address !== null ? resData.address.complement : '',
            neighborhood:
              resData.address !== null ? resData.address.neighborhood : '',
            city: resData.address !== null ? resData.address.city : '',
            state: resData.address !== null ? resData.address.state : ''
          }
        }
      })
      .catch(err => {
        this.$toast.error(err.message)
      })
      .finally(() => {
        this.dataLoaded = true
        isLoading.hide()
      })
  },
  watch: {
    'clinicData.address.zipCode': function (v) {
      if (this.dataLoaded && v.length === 9) {
        this.searchCEP()
      }
    }
  },
  methods: {
    searchCEP() {
      if (this.clinicData.address.zipCode.length === 9) {
        cep(this.clinicData.address.zipCode)
          .then(res => {
            this.clinicData.address.address = res.street
            this.clinicData.address.neighborhood = res.neighborhood
            this.clinicData.address.city = res.city
            this.clinicData.address.state = res.state

            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            console.log('Err', err)
          })
      }
    },
    async updateClinic() {
      const isLoading = this.$loading.show()

      const clinicData = new FormData()
      this.helpers.buildFormData(clinicData, this.clinicData)
      clinicData.append('_method', 'PUT')

      this.api
        .updateClinic(this.clinicData.id, clinicData)
        .then(res => {
          const resData = res.data
          this.eventHub.$emit('clinicUpdated', resData)
          this.$toast.success('Clínica atualizada com sucesso');
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          isLoading.hide()
        })

      if (this.image.cropedImage !== '') {
        await this.saveClinicBrand(this.image.cropedImage);
      }
    },
    resetCrop() {
      this.image.selectedFile = ''
    },
    onFileSelect(e) {
      const file = e.target.files[0]

      if ((file.size * 8 / (8 * 1000)) > this.maxImageSize) {
        this.$toast.warning(`A imagem deve ter no máximo ${this.maxImageSize}kB`);
        return;
      }

      this.mime_type = file.type
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.image.selectedFile = event.target.result
          this.$refs.cropper.replace(this.image.selectedFile)
        }
        reader.readAsDataURL(file)
        this.$bvModal.show('crop-modal')
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async cropImage() {
      this.image.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.image.cropedBlob = blob
      });
      this.$bvModal.hide('crop-modal')
    },
    saveClinicBrand(file) {
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      return new Promise((resolve, reject) => {
        const dataForm = new FormData();
        dataForm.append(`file`, file);
        dataForm.append(`type`, this.mime_type.split('/')[1]);
        this.api.saveClinicBrand(clinic.id, dataForm)
        .then(res => resolve(res.data))
        .catch(reject);
      })
    },
    getBrandTemporaryUrl(brand) {
      return new Promise((resolve, reject) => {
        const path = `public/images/clinics/${brand}`
        this.api.getDocTemporaryUrl(path)
        .then(res => resolve(res.data))
        .catch(reject);
      })
    },
  }
}
</script>

<style lang="scss">

#crop-modal {
  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 0;
  }

}
#clinic-data {
  .clinic-data {
    .image-container {
      width: 240px;
      margin-right: 24px;

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        min-height: 120px;
        background-color: var(--neutral-100);
        border: 1px solid var(--neutral-200);
        border-radius: 8px;
        position: relative;
        margin-bottom: 16px;

        cursor: pointer;

        img {
          border-radius: 8px;
        }

        .center {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: var(--neutral-500);
        }

        .icon {
          width: 24px;
          height: 24px;
          background-color: var(--blue-500);
          border: 2px solid var(--neutral-000);
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 8px;

          svg {
            width: 12px;
            fill: var(--neutral-000);
          }
        }
      }

      .help {
        font-weight: 600;
        font-size: 12px;
        color: var(--type-placeholder);
      }
    }

    hr {
      margin: 32px 0 56px 0;
      border-top: 1px solid var(--neutral-200);
    }
  }
}
</style>
